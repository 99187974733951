<template>
    <div :style="{ height: reportHeight + 'px' }" v-resize="setHeight" class="mb-3">
        <HummingbirdReportViewer v-if="property_id && spaceGroupProfile?.is_active && apwSettingState" :key="report_key" :show_divider="false" :show_default_report_option="false"
            :actions_panel="['bulk_edit']"
            :show_property_selector="false" :grid-conf="gridConf" :column-conf="columnConf" left_slot
            report_type="property_apw_management" 
            >
            <template v-slot:left>
                <span v-if="!spaceGroupProfile">
                    <v-skeleton-loader max-width="70%" type="text" class="profile-loader" />
                    <!--default will be 5-->
                </span>
                <div v-else class="hb-font-header-3-medium pt-2">
                    Grouping Profile:
                    <span :class="{ none: !spaceGroupProfile }">{{
                        spaceGroupProfile.grouping_profile_name || "None"
                    }}</span>
                </div>
            </template>
        </HummingbirdReportViewer>
        <HbEmptyState v-show="showPlaceholder" :message="placeholderText" />
        <hb-notification
        title="Note:"
        type="caution"
        not-dismissable
        v-if="!(property_id && spaceGroupProfile?.is_active && apwSettingState) && !showPlaceholder"
      >
      Advanced Reservations and Rentals is not enabled for this property. To enable it, please follow these steps: </br>
      <ul class="list-disc pl-5">
        <li>Once the space groups are created/confirmed, navigate to <strong> Hummingbird Settings > Lead Management. </strong> </li>
        <li>Select your property, scroll to the <strong> Advanced Reservations and Rentals </strong> section </li>
        <li>Select the desired <strong> Space Group </strong> from the dropdown and click Save.</li>
        <li>It automatically enables the <strong>Advanced reservations and Rentals</strong> section.</li>
      </ul>
      </hb-notification>
        <AssignApwPlan v-model="showAssignApwPlan" v-if="showAssignApwPlan" :spaceGroup="spaceGroupDetails" :propertyId="property_id" @plan-assigned="report_key++"></AssignApwPlan>
        <confirmation-pop-up v-model="planStatusConfirm" :type="this.spaceGroupDetails.spacegroup_apw_management_active == 1 ? 'disable' : 'enable'" :message="`Are you sure you want to ${this.spaceGroupDetails.spacegroup_apw_management_active == 1 ? 'Disable' : 'Enable'} the Advanced Reservations and Rentals plan you assigned to this space category?`" :title="`Confirm ${this.spaceGroupDetails.spacegroup_apw_management_active == 1 ? 'Disable' : 'Enable'}`" @update-status="planStatusUpdate"></confirmation-pop-up>
    </div>
</template>

<script type="text/babel">
import api from "../../assets/api";
import HummingbirdReportViewer from "../assets/HummingbirdReportViewer.vue";
import MultiLineRenderer from "@/components/BI/MultiLineRenderer.vue";
import BooleanCellRenderer from "@/components/BI/BooleanCellRenderer";
import PlanCellRenderer from "@/components/BI/PlanCellRenderer";
import ApwManagePlanAction from "./ApwManagePlanAction.vue";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "../../EventBus";
import EmptyStateContainer from "../assets/EmptyStateContainer.vue";
import AssignApwPlan from "./AssignApwPlan.vue";
import ConfirmationPopUp from './ConfirmationPopUp.vue';
import { notificationMixin } from "../../mixins/notificationMixin";


export default {
    data() {
        return {
            spaceGroupProfile: {},
            reportHeight: 0,
            showAssignApwPlan: false,
            spaceGroupDetails: {},
            planStatusConfirm: false,
            report_key: 0,
            apwSettingState: false,
        }
    },
    components: {
        HummingbirdReportViewer,
        MultiLineRenderer,
        BooleanCellRenderer,
        PlanCellRenderer,
        EmptyStateContainer,
        AssignApwPlan,
        ConfirmationPopUp
    },
    mixins: [notificationMixin],
    props: ['property_id'],
    async created() {
        await this.fetchSpaceGroup();
        this.triggerTableRefresh();
        EventBus.$on('spaceGroupReportUpdated', ()=>{ this.report_key++ })
        EventBus.$on("apwManagementByPropertyEvent", ({ eventType, data }) => {
            if (eventType == 'assignOnlinePlan') {
                this.assignOnlinePlan(data);
            } else if (eventType == 'assignInstorePlan') {
                this.assignInstorePlan(data);
            } else if (eventType == 'planStatus'){
                this.updatePlanStatus(data);
            }
        }
        );
    },
    beforeDestroy() {
        EventBus.$off("apwManagementByPropertyEvent");
        EventBus.$off("spaceGroupReportUpdated");
        this.property_id = ''
    },
    computed: {
        ...mapGetters({
            spaceData: "revManStore/getSpaceData",
        }),
        hasSpaceData() {
            return Object.keys(this.spaceData).length
        },
        gridConf() {
            return {
                props: {
                    BIHelpers: {
                        kebab: {
                            enabled: true,
                            icon: "mdi-dots-vertical",
                            component: {
                                definition: ApwManagePlanAction,
                                props: {
                                    propertyId: this.property_id,
                                },
                            },
                            action: "click",
                        },
                    },
                    getRowId: (params) => params.data.spacegroup_id_hash,
                    // animateRows: true,
                    defaultColDef: {
                        floatingFilter: false,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                    },
                    getRowHeight: (params) =>
                        params?.data?.spacegroup_summary ? 60 : 48,
                    // pagination: false
                },
            };
        },
        columnConf() {
            return {
                spacegroup_apw_management_active: {
                    cellRenderer: "BooleanCellRenderer",
                    pinned: "right",
                    lockPosition: "right",
                    suppressNavigable: true,
                    cellRendererParams: {
                        icon: true,
                    },
                    cellClass: "boolean-icons",
                },
                spacegroup_online_apw_plan: {
                    pinned: "right",
                    lockPosition: "right",
                    suppressNavigable: true,
                },
                spacegroup_instore_apw_plan: {
                    pinned: "right",
                    lockPosition: "right",
                    suppressNavigable: true,
                },
                spacegroup_spacetype: {
                    autoWidth: true,
                    pinned: "left",
                },
                spacegroup_size: {
                    autoWidth: true,
                    pinned: "left",
                },
                spacegroup_amenities: {
                    autoWidth: true,
                    pinned: "left",
                },
            };
        },
        showPlaceholder() {
            return !this.property_id //||  !this.spaceGroupProfile?.is_active || this.apwSettingState === false
        },
        placeholderText() {
            let message = "Please select a Property to manage Advanced Reservations and Rentals";
            if (this.property_id)
                message = "This property does not have Advanced Reservations and Rentals Activated. To activate Advanced Reservations and Rentals, please reach out to support@tenantinc.com";
            return message;
        },
    },
    methods: {
        ...mapActions({
            setRestGridEnableAction: "reportStore/setRestGridEnableAction",
        }),
        async fetchSpaceGroup() {
            
            try {
                this.spaceGroupProfile = await api.get(
                    this,
                    api.APW_SETTING + 'properties/' + this.property_id,
                );
            } catch (error) {
                this.showMessageNotification({ type: 'error', description: err });
            }
        },
        setHeight() {
            let header = 0;
            let stepper = 100;
            let tabs = 12; // 48
            let propertySelector = -53;
            let footer = 78;
            let heading = 48;
            let padding = 30;
            let notification = this.notification ? 47 : -12;
            this.reportHeight =
                window.innerHeight -
                notification -
                header -
                stepper -
                tabs -
                heading -
                footer -
                padding +
                propertySelector;
        },
        setDynamicRuntimeProperties(data = null) {
            this.$store.commit("reportStore/setDynamicRunParam", {
                propertyIDArray: data ? [data] : undefined,
            });
        },
        triggerTableRefresh(columns = []) {
            if (!this.$route.query.dynamicRun) {
                this.$router.push({ path: "", query: { dynamicRun: true } });
            }
            this.setDynamicRuntimeProperties(this.property_id);
            // Refresh table data
            EventBus.$emit("unit_edited");
        },
        assignInstorePlan(data){
            this.showAssignApwPlan = true;
            data.type = 'Instore';
            this.spaceGroupDetails = data;
        },
        assignOnlinePlan(data){
            this.showAssignApwPlan = true;
            data.type = 'Online';
            this.spaceGroupDetails = data;
        },
        updatePlanStatus(data){
            this.spaceGroupDetails = data;
            this.planStatusConfirm = true;
            
        },
        async planStatusUpdate(){
            try {
                let data = {
                    spacegroup_id: this.spaceGroupDetails.spacegroup_id,
                    plan_status: this.spaceGroupDetails.spacegroup_apw_management_active == 1 ? 0 : 1
                };
                await api.post(this, api.APW_MANAGEMENT +'properties/' + this.property_id + '/plan-status', data);
                this.showMessageNotification({ type: 'success', description: "Plan status updated successfully" });
                this.planStatusConfirm = false;
                this.report_key++;
            } catch (error) {
                console.log(error);
            }
        },
        async fetchSettings() {
            let property_query=''
            property_query = `&property_id=${this.property_id}`
            let response = await api.get(this.$app, api.SETTINGS + `apw-details?category=leadManagement${property_query}`)
            // response value is in string

            this.apwSettingState = (response?.settings[0]?.value === "1")  || false
        },
    },
    watch: {
        property_id: {
            async handler(prev, next) {
                // Let it run on background
                await this.fetchSpaceGroup();
                this.setRestGridEnableAction(true);
                this.triggerTableRefresh()
                this.report_key++;
                await this.fetchSettings();
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.profile-loader {
    margin-top: 6px;
}
</style>