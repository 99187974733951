<template>
    <div class="searchbar-margin-top">
      <!-- if there is only one property at the facility -->
        <div v-if="properties.length === 1" class="facility-search--input only-one-property">
            <v-row class="white--text" style="margin-top: 2px; margin-left: 3px;" no-gutters>
                <div style="margin-right: 2px;">
                    <hb-icon color="#ffffff">
                        mdi-facility-custom-2
                    </hb-icon>
                </div>
                <div class="ml-3 hb-font-header-3-medium">
                    <span v-if="properties[0].number">{{properties[0].number}} - </span>{{properties[0].Address.city}} - {{properties[0].Address.address}}
                </div>
            </v-row>
        </div>
      <!-- if there is more than one property at the facility -->
        <div id="search-box" v-if="properties.length > 1" class="facility-search--input" :class="[selected.length === 1 && !selectedPropertyGroup.id ? 'facility-search-only-one-property' : 'facility-search-multiple-properties', {'search-off': isMobileSearch}]" @click="$emit('update:hasFocus', true)">
            <div class="facility-search--icons-left" :class="hasFocus || selected.length === 1 && !selectedPropertyGroup.id ? 'ml-2' : 'ml-n2'">
                <span class="facility-search--icon-search">
                    <span v-if="!query && !hasFocus">
<!--                        <span v-if="activeTab.startsWith('Company') === false && activeTab.startsWith('Facility Group') === false">-->
                        <span v-if="selected.length === 1 && !selectedPropertyGroup.id">
                            <hb-icon class="mt-1 mr-1" color="#ffffff">
                                mdi-facility-custom-2
                            </hb-icon>
                        </span>
                        <span v-else>
                            <v-chip
                                class="mt-n1"
                                color="#ffd600"
                                text-color="#101318"
                                style="border:2px solid #101318"
                                >
                                <hb-icon class="ml-n2 mt-1 mr-1" color="#101318">
                                    mdi-facility-custom-2
                                </hb-icon>
                                <span class="hb-font-header-3-medium mr-n1" style="margin-top:-2px;">{{storageIconData}}</span>
                            </v-chip>
                        </span>
                    </span>
                    <span v-if="query || hasFocus"><v-icon id="magnify-icon" class="hb-text-night pb-1 mr-1" style="padding-top:1px;" size="24">mdi-magnify</v-icon></span>
                </span>
            </div>
            <input
                :value="query"
                @input="$emit('update:query', $event.target.value)"
                @focus="onFocus"
                class="hb-font-header-3-medium"
                :placeholder="placeholder"
                name="faclility-search"
                id="faclility-search"
                autocomplete="cc-faclility-search"
            />
            <div style="float:right;padding-top:2px;">
                <v-btn icon retain-focus-on-click style="background:none" x-small v-if="!query && !hasFocus && !isMobileSearch" @click.stop="$emit('update:hasFocus', true)">
                  <hb-icon :color="selected.length === 1 && !selectedPropertyGroup.id ? '#ffffff' : '#101318'">mdi-magnify</hb-icon>
                </v-btn>
                <v-btn icon x-small style="background:#C6CDD4" color="#ffffff" v-if="query && hasFocus" @click.stop="clear"><v-icon size="10">mdi-close</v-icon></v-btn>
            </div>
        </div>
      <!-- if there is no property -->
        <div v-if="properties.length < 1" class="facility-search--input only-one-property"  style="background:#004247">
            <v-row class="white--text" style="margin-top: 2px; margin-left: 3px;" no-gutters>
                <div style="margin-right: 2px;">
                    <hb-icon color="#ffffff">
                        mdi-facility-custom-2
                    </hb-icon>
                </div>
                <div class="ml-3 hb-font-header-3">
                    <span>No Property Info Available</span>
                </div>
            </v-row>
            <div style="float:right;padding-top:2px;margin-top: 2px;">
                <hb-icon color="#ffffff">mdi-magnify</hb-icon>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'FacilitySearchBar',
    data() {
        return {
            isFocusTrigger: true
        }
    },
    props: [
        'query',
        'activeTab',
        'selectedPropertyGroup',
        'filtered',
        'hasFocus',
        'properties',
        'selected',
    ],
    created(){

    },
    computed: {
        ...mapGetters({
            hasPermission: 'authenticationStore/rolePermission',
            hasCharmSubscribedPropertySelected: 'charmSubscriptionStore/hasCharmSubscribedPropertySelected'
        }),
        placeholder(){
            let placeholder = '';
            if(this.selectedPropertyGroup.id){
                let propertiesNumber = this.selectedPropertyGroup.properties.length;
                placeholder = `${this.selectedPropertyGroup.name} (${propertiesNumber} ${propertiesNumber > 1 ? `Properties`: `Property`} Selected)`;
            }
            else if(this.selected.length === 1){
              let property = this.selected[0];
              if(property.number){
                placeholder +=  property.number + ' - ';
              }
              placeholder +=  property.Address.city + ' - ' +  property.Address.address;
            } else if(this.selected.length === this.properties.length && this.hasPermission('mini_charm') && this.hasCharmSubscribedPropertySelected && this.$vuetify.breakpoint.width < 425) {
                placeholder = 'Group';
            } else if(this.selected.length === this.properties.length && this.$vuetify.breakpoint.xs) {
                placeholder = 'Property Group';
            } else if(this.selected.length === this.properties.length) {
                placeholder = 'Company (' + this.selected.length + ' Properties Selected)';
            } else {
                placeholder = 'Property Group (' + this.selected.length + ' Properties Selected)';
            }

            // console.log("placeholder", hasPermission('mini_charm') && hasCharmSubscribedPropertySelected);

            return placeholder;
        },
        storageIconData(){
            if(this.selectedPropertyGroup.id) return this.selectedPropertyGroup.properties.length;
            return this.selected.length;
        },
        isMobileSearch() {
            return this.hasPermission('mini_charm') && this.hasCharmSubscribedPropertySelected && this.$vuetify.breakpoint.width < 425
        }
    },
    methods: {
        ...mapActions({
            setFilter: 'propertiesStore/setFilter',
            setProperties: 'propertiesStore/setProperties',
            setProperty: 'propertiesStore/setProperty',
        }),
        onFocus() {
            if(this.isFocusTrigger) { this.$emit('update:hasFocus', true); }
            this.isFocusTrigger = !this.$vuetify.breakpoint.xs;
        },
        clear(){
                this.$emit('update:query');
                // this.$emit('update:activeTab', 'My Properties');
                // this.$emit('update:selected', []);
                //this.setProperties();
                //this.$store.dispatch('dashboardsStore/getDashboards', { pid: null });
                //this.setFilter();
                //this.setProperty({});
                //var companyLabel = 'Company (' + this.properties.length + ' Properties Selected)';
                //this.$emit('update:activeTab', companyLabel);
            //this.$emit('update:hasFocus', false);
            // this.$emit('update:selected', []);
        }
    }
}
</script>
<style lang="scss">

#facility-search {

    width:760px;

    &.isActive {
        .facility-search--input {
            transition: all 300ms ease-in-out;
            color: rgba(0,0,0,1);
            background: rgba(255,255,255,1) !important;
            input {
                color: rgba(0,0,0,1);
            }
            input::placeholder {
                color: rgba(0,0,0,0.5);
            }
            .facility-search--icons-left {
                color: rgba(255,255,255,0.5);
                .facility-search--icon-search {
                    color: rgba(0,0,0,1);
                }
                .facility-search--icon-selected {
                    span {
                        transition: all 300ms ease-in-out;
                        color: rgba(0,0,0,1);
                        border: 1px solid rgba(0,0,0,1);
                    }
                }
            }
            .facility-search--icons-right {
                .facility-search--icon-close {
                    color: rgba(255,255,255,1);
                    background: rgba(155,155,155,1);
                    opacity: 0.5;
                    padding: 3px 8px;
                    &:hover {
                        opacity: 1;
                        background: #00b2ce;
                        color: #fff;
                    }
                }
            }
            .facility-search--input{
                transition: all 300ms ease-in-out;
                color: rgba(0,0,0,1);
                background: rgba(255,255,255,1);
                input,
                .icon {
                    transition: all 300ms ease-in-out;
                    color: rgba(0,0,0,1);
                }
                input {

                        color: rgba(0,0,0,1);
                    &:active,
                    &:focus,
                    &::placeholder {
                        transition: all 300ms ease-in-out;
                        color: rgba(0,0,0,1);
                    }
                }
            }
        }
    }

    .facility-search--input {
        transition: all 300ms ease-in-out;
        display: flex;
        flex-wrap: nowrap;
        padding: 8px 20px;
        border-radius: 40px;

        &.search-off {
            padding: 8px 0px 8px 20px;
        }

        input {
            flex: 1 auto;
            padding: 1px 5px;
            width: 100%;
            &:active,
            &::placeholder {
                transition: all 300ms ease-in-out;
                color: rgba(255,255,255,0.5);
                background: none;
                border: none;
                outline: none;
            }
            &:focus {
                background: none;
                border: none;
                outline: none;
            }
        }
        input::placeholder{
            font-weight:500;
            opacity: 1;
        }
        .facility-search--icons-left {
            position: relative;
            left: -5px;
            color: rgba(255,255,255,0.5);
            .facility-search--icon-search {
                position: relative;
                top: 2px;
            }
            .facility-search--icon-selected {
                margin-left: 5px;
                span {
                    color: rgba(255,255,255,0.5);
                    border: 1px solid rgba(255,255,255,0.5);
                    border-radius: 15px;
                    font-size: 0.8em;
                    padding: 1px 5px;
                }
            }
        }

        .facility-search--icons-right {
            position: relative;
            right: -5px;
            min-width: 20px;
            button,
            button:active,
            button:focus {
                border: none;
                outline: none;
                background: none;
            }
            .facility-search--icon-dropdown {
                font-size: 0.75em;
                line-height: 1.6em;
                color: rgba(255,255,255,0.5);
                border: 1px solid rgba(255,255,255,0.5);
                border-radius: 5px;
            }
            .facility-search--icon-close {
                flex: 1 auto;
                font-size: 0.70em;
                line-height: 1.5em;
                border-radius: 10px;
                color: rgba(255,255,255,1);
                background: rgba(155,155,155,1);
                opacity: 0.5;
                &:hover {
                    opacity: 1;
                    background: #00b2ce;
                    color: #fff;
                }
            }
        }
    }

    .facility-search-multiple-properties{
        background: repeating-linear-gradient(135deg, #FFD600, #FFD600 5px, #E0BD09 0px, #E0BD09 11px);
    }

    .facility-search-only-one-property{
        background: #004247;
        font-weight:18px;
        border:0;
    }

    .facility-search-multiple-properties:hover{
        background: repeating-linear-gradient(135deg, #FFF9DA, #FFF9DA 5px, #F3EED0 0px, #F3EED0 11px);
    }

    .facility-search-only-one-property:hover{
        background: #006A72;
    }

    .facility-search-multiple-properties input::placeholder{
        color: #101318;
        font-size: 16px;
    }

    .facility-search-only-one-property input::placeholder{
        color: #ffffff;
        font-size: 16px;
    }

    .searchbar-margin-top{
        margin-top: 8.5px;
        margin-bottom: 10.5px;
    }
    
}

</style>
